'use client';

import { cn } from '@/lib/utils';
import { usePathname } from 'next/navigation';
import { FC, useEffect, useState } from 'react';
import SupportButton from '../ui/support-button';
interface FooterProps {}
const PATHS_WITHOUT_CHANGE = ['/editor', '/login', '/signup'];
export const Footer: FC<FooterProps> = () => {
  const pathname = usePathname();
  const [path, setPath] = useState<string | null>(null);
  useEffect(() => {
    setPath(pathname);
  }, [pathname]);
  return <div className={cn('fixed right-4 bottom-4 z-50', {
    'sm:bottom-4 bottom-20': !PATHS_WITHOUT_CHANGE.some(innerPath => path?.includes(innerPath))
  })} data-sentry-component="Footer" data-sentry-source-file="footer.tsx">
      <SupportButton data-sentry-element="SupportButton" data-sentry-source-file="footer.tsx" />
    </div>;
};